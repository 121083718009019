import React, { useState } from "react";
import styled from "styled-components";
import QRCode from "react-qr-code";
import { motion } from "framer-motion";

const PromoContainer = styled(motion.div)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 1000;
`;

const PromoText = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 18px;
    margin: 0 0 10px 0;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
`;

const PromoBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <PromoContainer
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <QRCode value="https://pettitsoftware.com/apk/shopfloor.apk" size={80} />
      <PromoText>
        <h2>Download Our App</h2>
        <p>Get the latest updates and features!</p>
      </PromoText>
      <CloseButton onClick={() => setIsVisible(false)}>&times;</CloseButton>
    </PromoContainer>
  );
};

export default PromoBanner;
