// src/components/Footer.js
import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
`;

const FooterLinks = styled.div`
  margin: 20px 0;
  a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
  }
`;

const SocialMedia = styled.div`
  a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
  }
`;

const Footer = () => {
  return (
    <FooterContainer id="contact">
      <FooterLinks>
        <a href="/">Home</a>
        <a href="/#features">Features</a>
        <a href="/#testimonials">Testimonials</a>
        <a href="/contact">Contact</a>
      </FooterLinks>
      <p>&copy; 2025 Pettit Software. All rights reserved.</p>
    </FooterContainer>
  );
};

export default Footer;
