import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

// Import images
import image1 from "../assets/images/image1.jpg";
import image2 from "../assets/images/image2.jpg";
import image3 from "../assets/images/image3.jpg";
import image4 from "../assets/images/image4.jpg";

const HeroSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  position: relative;
  transition: background-image 1s ease-in-out;

  @media (max-width: 768px) {
    height: auto;
    padding: 20px;
    flex-direction: column;
  }
`;

const HeroContent = styled.div`
  max-width: 800px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const HeroDescription = styled.p`
  font-size: 1.5rem;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 20px;
  }
`;

const HeroActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const HeroButton = styled.a`
  background-color: #78c000;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 50px;
  font-weight: bold;
  font-size: 16px;

  &:hover {
    background-color: #6bab00;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

const images = [image1, image2, image3, image4];

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 7000); // Change image every 7 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <HeroSection
      as={motion.div}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
    >
      <HeroContent>
        <HeroTitle>Comprehensive Shop Floor Process Management Tool</HeroTitle>
        <HeroDescription>
          Streamline your operations and manage processes efficiently.
        </HeroDescription>
        <HeroActions>
          <HeroButton href="/free-demo">Get Free Demo</HeroButton>
        </HeroActions>
      </HeroContent>
    </HeroSection>
  );
};

export default Hero;
