import React, { useState, useEffect } from "react";
import styled from "styled-components";
import image1 from "../assets/images/image1.jpg";
import image2 from "../assets/images/image2.jpg";
import image3 from "../assets/images/image3.jpg";
import image4 from "../assets/images/image4.jpg";

const DemoPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #fff;
  text-align: center;
  background-size: cover;
  background-position: center;
  position: relative;
  transition: background-image 1s ease-in-out;
`;

const FormContainer = styled.form`
  max-width: 600px;
  width: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  color: #fff;
`;

const FormTitle = styled.h1`
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;

  &:focus {
    outline: none;
    border: 2px solid #78c000;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  resize: none;

  &:focus {
    outline: none;
    border: 2px solid #78c000;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  font-size: 18px;
  background-color: #78c000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #6bab00;
  }
`;

const images = [image1, image2, image3, image4];

const FreeDemo = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 7000); // Change image every 7 seconds

    return () => clearInterval(interval);
  }, []);

  const [formData, setFormData] = useState({
    companyName: "",
    name: "",
    email: "",
    location: "",
    numberOfOperators: "",
    comments: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/contactform/free-demo.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded", // Use this content type for PHP
        },
        body: new URLSearchParams(formData).toString(), // Convert form data to URL-encoded format
      });

      if (response.ok) {
        alert("Thank you for your inquiry! We'll get back to you soon.");
        setFormData({
          companyName: "",
          name: "",
          email: "",
          location: "",
          numberOfOperators: "",
          comments: "",
        });
      } else {
        alert("Failed to send your inquiry. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <DemoPageContainer
      style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
    >
      <FormContainer onSubmit={handleSubmit}>
        <FormTitle>Request a Free Demo</FormTitle>
        <FormGroup>
          <Label htmlFor="companyName">Company Name</Label>
          <Input
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="name">Your Name</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Your Email</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="location">Location</Label>
          <Input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="numberOfOperators">Number of Operators</Label>
          <Input
            type="number"
            id="numberOfOperators"
            name="numberOfOperators"
            value={formData.numberOfOperators}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="comments">Additional Comments</Label>
          <TextArea
            id="comments"
            name="comments"
            rows="4"
            value={formData.comments}
            onChange={handleChange}
          />
        </FormGroup>
        <SubmitButton type="submit">Submit</SubmitButton>
      </FormContainer>
    </DemoPageContainer>
  );
};

export default FreeDemo;
