// src/components/Testimonials.js
import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const TestimonialsSection = styled.section`
  padding: 50px 20px;
  background-color: #f8f9fa;
  text-align: center;
`;

const TestimonialsGrid = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const TestimonialItem = styled(motion.div)`
  max-width: 300px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 20px;
`;

const TestimonialQuote = styled.p`
  font-size: 16px;
  font-style: italic;
`;

const TestimonialAuthor = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
`;

const Testimonials = () => {
  const testimonials = [
    {
      quote: "This product has transformed our workflow!",
      author: "Jane Doe, Operations Manager",
    },
    {
      quote: "Incredibly easy to use and has streamlined our operations.",
      author: "John Smith, Production Supervisor",
    },
    {
      quote: "Highly recommend to everyone.",
      author: "Sam Wilson, Quality Assurance",
    },
  ];

  return (
    <TestimonialsSection id="testimonials">
      <h1>What Our Customers Say</h1>
      <TestimonialsGrid>
        {testimonials.map((testimonial, index) => (
          <TestimonialItem
            as={motion.div}
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <TestimonialQuote>{testimonial.quote}</TestimonialQuote>
            <TestimonialAuthor>{testimonial.author}</TestimonialAuthor>
          </TestimonialItem>
        ))}
      </TestimonialsGrid>
    </TestimonialsSection>
  );
};

export default Testimonials;
