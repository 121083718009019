// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GlobalStyle from "./styles/globalStyles";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Features from "./components/Features";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import PromoBanner from "./components/PromoBanner";
import FreeDemo from "./components/FreeDemo";
import Contact from "./components/Contact";
import { motion } from "framer-motion";
import "./App.css"; // Import your CSS file

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Header />
      <Routes>
        {/* Home Page */}
        <Route
          path="/"
          element={
            <>
              <Hero />
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Features />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                {/* <Testimonials /> */}
              </motion.div>
              <Footer />
              <PromoBanner />
            </>
          }
        />

        {/* Free Demo Page */}
        <Route path="/free-demo" element={<FreeDemo />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
