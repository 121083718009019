import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  FaMapMarkedAlt,
  FaArrowsAltH,
  FaChartLine,
  FaMobileAlt,
  FaCloud,
  FaBarcode,
} from "react-icons/fa";
import { motion } from "framer-motion";
import "../assets/styles/Features.css";

const FeaturesSection = styled.section`
  padding: 50px 20px;
  background-color: #fff;
  text-align: center;
`;

const CarouselContainer = styled.div`
  position: relative;
  max-width: 80%;
  margin: 0 auto;
  overflow: hidden;
`;

const FeaturesGrid = styled(motion.div)`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${(props) => props.translate}%);
`;

const FeatureItem = styled.div`
  flex: 0 0 33.33%; /* Show 3 items at a time */
  max-width: 33.33%;
  text-align: center;
  padding: 25px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex: 0 0 100%; /* Show 1 item at a time */
    max-width: 100%;
  }
`;

const FeatureIcon = styled.div`
  font-size: 50px;
  color: #78c000;
  margin-bottom: 15px;
`;

const FeatureTitle = styled.h2`
  font-size: 24px;
  margin: 10px 0;
`;

const FeatureDescription = styled.p`
  font-size: 16px;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 30px;
  color: #78c000;
  cursor: pointer;
  z-index: 10;

  &:hover {
    color: #78c000;
  }

  ${(props) =>
    props.left
      ? "left: 10px;"
      : "right: 10px;"}/* Positioned inside the padding */
`;

const Features = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const features = [
    {
      icon: <FaMapMarkedAlt />,
      title: "Process Mapping to Products",
      description:
        "Easily map various processes to specific products, ensuring each product follows a tailored workflow. This feature allows for precise customization and management of product-specific processes.",
    },
    {
      icon: <FaArrowsAltH />,
      title: "Drag and Drop Process Ordering",
      description:
        "Reorder processes effortlessly using a drag-and-drop interface. This intuitive feature enables users to quickly adjust the sequence of processes, ensuring optimal workflow and efficiency.",
    },
    {
      icon: <FaChartLine />,
      title: "Real-time Progress Tracking",
      description:
        "Monitor the progress of each product in real time. The progress tracker provides visual feedback, including special indicators for scrapped items, ensuring users are always aware of the current status.",
    },
    {
      icon: <FaMobileAlt />,
      title: "Compatible with Phones and Tablets",
      description:
        "Use your existing Android devices like phones or tablets to run our application. There's no need to invest in additional hardware, reducing costs while maintaining efficiency.",
    },
    {
      icon: <FaBarcode />,
      title: "Accurate Code Scanning",
      description:
        "Quickly scan even the smallest DataMatrix codes and other types of barcodes with precision. Our advanced algorithm ensures fast and reliable scanning, perfect for high-demand environments.",
    },
    {
      icon: <FaCloud />,
      title: "Cloud Storage with Instant Data Backup",
      description:
        "Our cloud-based application saves your data instantly once submitted. Enjoy peace of mind with real-time backups, ensuring no data is lost and making it accessible from anywhere.",
    },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === features.length - (isMobile ? 1 : 3) ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? features.length - (isMobile ? 1 : 3) : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 7000); // Automatically scroll every 7 seconds

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <div className="features-container">
      <FeaturesSection id="features">
        <h1>Key Features</h1>
        <CarouselContainer>
          <ArrowButton left onClick={handlePrev}>
            &#8592;
          </ArrowButton>
          <FeaturesGrid translate={-currentIndex * (isMobile ? 100 : 33.33)}>
            {features.map((feature, index) => (
              <FeatureItem key={index}>
                <FeatureIcon>{feature.icon}</FeatureIcon>
                <FeatureTitle>{feature.title}</FeatureTitle>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </FeatureItem>
            ))}
          </FeaturesGrid>
          <ArrowButton onClick={handleNext}>&#8594;</ArrowButton>
        </CarouselContainer>
      </FeaturesSection>
    </div>
  );
};

export default Features;
