import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import { motion } from "framer-motion";
import "../assets/styles/Header.css";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Nav = styled.nav`
  ul {
    display: flex;
    list-style: none;
    gap: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  li {
    position: relative;
  }

  li a {
    color: #034833; // Dark green color for the links
    text-decoration: none;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: #78c000; // Same color as the hero button
      color: #fff;
    }
  }

  li a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #78c000; // Same color as the hero button
    left: 0;
    bottom: -5px;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  li a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

const Header = () => {
  return (
    <HeaderContainer
      as={motion.div}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Logo />
      <Nav>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/#features">Features</a>
          </li>
          {/* <li>
            <a href="/#testimonials">Testimonials</a>
          </li> */}
          <li>
            <a href="/contact">Contact</a>
          </li>
        </ul>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
